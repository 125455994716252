import "../Styles/home.scss";
import Navigation from "../components/Utils/Navigation";
import { useState, useEffect } from "react";
import Settings from "../components/Settings/Settings";
import NavHeader from "../components/Utils/NavHeader";
import MyHome from "../components/MyHome/MyHome";
import NewDocument from "../components/Requests/NewDocument";
import SingleTask from "../components/Tasks/SingleTask";
import { Navigate } from "react-router-dom";
import jwt from "jsonwebtoken";

export default function Home(props) {
  const [showing, setShowing] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const pathname = window.location.pathname.split("/");

  const handleResize = () => {
    if (window.innerWidth < 1024) {
      setShowing(false);
    } else {
      setShowing(true);
    }
  };

  useEffect(() => {
    handleResize();
    const token = localStorage.getItem("webgfgfgggn");
    if (token) {
      try {
        const decoded = jwt.decode(token);
        if (Date.now() < decoded.exp * 1000) {
          setIsAuthenticated(true);
        }
      } catch (error) {
        console.error("Token decoding failed:", error);
      }
    }
    setLoading(false);
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  return (
    <div className="home">
      <div
        style={{ gridTemplateColumns: !showing ? "auto 1fr" : "250px 1fr" }}
        className="main"
      >
        <div className="left_panel">
          <Navigation showing={showing} setShowing={setShowing} />
        </div>
        <div className="right_panel">
          <NavHeader showing={showing} setShowing={setShowing} />
          <div className="full">
            {pathname[1] === "home" && pathname.length === 2 && (
              <MyHome showing={showing} setShowing={setShowing} />
            )}
            {pathname[1] === "singletask" && (
              <SingleTask showing={showing} setShowing={setShowing} />
            )}
            {pathname[2] === "account" && (
              <Settings showing={showing} setShowing={setShowing} />
            )}
            {pathname[2] === "checks" && (
              <NewDocument showing={showing} setShowing={setShowing} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
