
import img from "../../assets/images/people.png";


export default function TopBar(props) {
 
  return (
    <div className="top">
      <div className="left">
        <h1>Customer Portal</h1>
        <h3>Welcome back {props.UserID ? props.UserID.Name : ""}!</h3>
        <p>
          Welcome to our background check services! We are thrilled to have you
          on board and look forward to assisting you with your verification
          needs.
        </p>
      </div>
      <div className="right">
        <img src={img} alt="" />
      </div>
    </div>
  );
}
