import "../../Styles/myhome.scss";
import Requests from "../Requests/Requests";
import Disclaimer from "./Disclaimer";
import TopBar from "./TopBar";
import { useState } from "react";
import { useEffect } from "react";

export default function MyHome(props) {
   const [UserID, setUserID] = useState(null);

   const jwt = require("jsonwebtoken");
   useEffect(() => {
     const token = localStorage.getItem("webgfgfgggn");
     if (token) {
       try {
         var decoded = jwt.decode(token);
         if (Date.now() >= decoded.exp * 1000) {
           window.location.href = "/";
         } else {
           setUserID(decoded);
         }
       } catch (error) {
         window.location.href = "/";
       }
     } else {
       window.location.href = "/";
     }
   }, []);
  return (
    <div className="myhome">
      <div className="mob">
        <h1>Customer Portal</h1>
        <p>Welcome back {UserID ? UserID.Name : ""}!</p>

        <br />
        <h2>My Jobs</h2>
        <hr />
      </div>
      <div className="topdivider">
        <TopBar UserID={UserID} />
        <Disclaimer />
      </div>

      <Requests />
    </div>
  );
}
