import { useRef, useState } from "react";
import Loading from "../Utils/Loading";
import WaveLoading from "../Utils/WaveLoading";
import Input from "../Utils/Input";
import Button from "../Utils/Button";

export default function ForgotPassword(props) {
  const email = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState("");
  const [body, updateBody] = useState({ Email: null });

  const resetPassword = () => {
    let d = body;
    d.Email = email.current.getValue();
    updateBody(d);
    setIsError("");

    const validateEmail = (email) => {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    if (!validateEmail(body.Email)) {
      return setIsError("Please provide a valid email address");
    }

    if (validateEmail(body.Email)) {
      setIsLoading(true);
      fetch("/api/mobile/forgot", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else throw Error("Failed");
        })
        .then((data) => {
          if (data.success) {
            setIsLoading(false);
            setIsError(data.success);
            setTimeout(() => {
              props.setForgot(false);
            }, 1000);
          } else {
            setIsError(data.error);
            setIsLoading(false);
          }
        })
        .catch((e) => {
          setIsLoading(false);
        });
    }
  };

  return (
    <div className="login">
      <div className="container">
        <i
          onClick={() => {
            props.setIsForgot(false);
          }}
          className="fa fa-times"
        ></i>
        <h3>Forgot Password</h3>

        <p>We'll generate a password and send to your email address</p>
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Input ref={email} type="email" placeholder="Your email" />

          <h6>{isError}</h6>
          <Button
            handleClick={resetPassword}
            value="Submit"
            className="button"
          />
        </form>

        {isLoading && <WaveLoading />}
      </div>
    </div>
  );
}
