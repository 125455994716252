import { React } from "react";
import logo from "../assets/images/logo.png";

function NotFound() {
  return (
    <div className="notfound">
      <div>
        <img src={logo} alt="" />
        <p>Oops! this page does not exist</p>
        <button onClick={()=>{
			window.location.href = "/"

		}}>Go Back</button>
        <a href="https://aropegroup.com">Visit our website</a>
      </div>
    </div>
  );
}

export default NotFound;
