import { useEffect, useRef, useState } from "react";
import "../../Styles/tasks.scss"
import WaveLoading from "../Utils/WaveLoading";

export default function CreateReport(props) {
  const [isLoading, setIsLoading] = useState(null);
  const [error, setError] = useState("");
  const [refresh, setRefresh] = useState(false);
  const sf = useRef();

  const [body, setBody] = useState({
    RequestID: props.RequestID,
    SubjectID: props.SubjectID,
    UserID: props.UserID,
    Attachment: null,
  });

  function createReport() {
    let d = body;
    d.Attachment = sf.current.files[0];
    setBody(d);
    if (d.Attachment === undefined) return setError("Attachment is required!");
    const formData = new FormData();

    for (const i in d) {
      formData.append(i, d[i]);
    }

    setIsLoading(true);
    setError("");
    fetch(`/api/reports/create`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
      },
      body: formData,
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error();
      })
      .then((data) => {
        if (data.success) {
          setError(data.success);
          setTimeout(() => {
            props.setRefresh(!props.refresh);
            props.setShowing(null);
          }, 1000);
        } else {
          setError(data.error);
        }

        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  }

  return (
    <div data-aos="fade-left" className="popup">
      <div className="pcontainer">
        <div style={{ backgroundColor: "green" }} className="tbar">
          <h3>{props.Title}</h3>
          <i
            onClick={() => {
              props.setShowing(null);
              props.setRefresh(!props.refresh);
            }}
            className="fa fa-times"
          ></i>
        </div>

        <div className="wcontent">
          <h3>Submit Report</h3>
          <p>
            The report will be available to the client portal. As well, an email
            will be send to the client automatically
          </p>
          <br />
          <div className="usrinput">
            <h4>Upload Report *</h4>
            <input ref={sf} type="file" label="Upload files *" accept=".pdf" />
          </div>
          <br />
          <h6>{error}</h6>
          <br />
          <button
            onClick={() => {
              createReport();
            }}
          >
            Submit
          </button>
          {isLoading && <WaveLoading />}
        </div>
      </div>
    </div>
  );
}
