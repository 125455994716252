import React from "react";
import "../Styles/landing.scss";
import Header from "./../components/Utils/Header";
import Footer from "../components/Utils/footer";
import { useEffect, useState } from "react";
import Hero from "../components/Landing/Hero";
import LoginPopup from "../components/Landing/LoginPopup";
import ForgotPassword from "../components/Landing/ForgotPassword";
import RegisterPopup from "../components/Landing/RegisterPopup";

export default function Landing() {
  const [isLogin, setIsLogin] = useState(null);
  const [isForgot, setIsForgot] = useState(null);
  const [isRegister, setIsRegister] = useState(null);

  return (
    <div className="landing">
      <Header setIsLogin={setIsLogin} setIsRegister={setIsRegister} />
      <Hero setIsLogin={setIsLogin} setIsRegister={setIsRegister} />
      <Footer />
      {isLogin && (
        <LoginPopup
          setIsForgot={setIsForgot}
          setIsLogin={setIsLogin}
          setIsRegister={setIsRegister}
        />
      )}
      {isForgot && (
        <ForgotPassword
          setIsForgot={setIsForgot}
          setIsLogin={setIsLogin}
          setIsRegister={setIsRegister}
        />
      )}
      {isRegister && (
        <RegisterPopup
          setIsForgot={setIsForgot}
          setIsLogin={setIsLogin}
          setIsRegister={setIsRegister}
        />
      )}
    </div>
  );
}
