export default function Disclaimer(props) {
  return (
    <div className="disclaimer">
      <div className="disc">
        <h3>Disclaimer</h3>
        <p>
          I give my consent to Arope Group Limited to conduct the Background
          Screening exercise.
        </p>
        <p>
          Understand that my participation in the Background Screening exercise
          is voluntary.
        </p>
      </div>
    </div>
  );
}
