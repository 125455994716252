import React, { useEffect, useRef, useState } from "react";
import "../../Styles/_header.scss";
import logo from "../../assets/images/logo.png";
import HeaderModal from "./modal_header";
import userguide from "../../assets/Arope Group ERP User Guide.pdf";

function NavLink(props) {
  return (
    <>
      {props.active === props.txt ? (
        <a data-aos="zoom-in" className="active" href={props.url}>
          {props.txt}
        </a>
      ) : (
        <a data-aos="zoom-in" href={props.url}>
          {props.txt}
        </a>
      )}
    </>
  );
}

export default function Header(props) {
  const [clicked, setClicked] = useState(false);
  const hd = useRef();

  useEffect(() => {
    window.addEventListener("scroll", changeCss, false);
  }, []);

  function changeCss() {
    if (this.scrollY > 0) {
      hd.current.style.position = "fixed";
    } else {
      hd.current.style.position = "relative";
    }
  }

  return (
    <>
      <div ref={hd} className="header">
        <div className="contacts">
          <div className="ccontainer">
            <div>
              <img
                onClick={() => {
                  window.location.href = "/";
                }}
                src={logo}
                alt=""
              />
              <h1>Client Portal</h1>
            </div>
            <a href="tel:+25425214688">
              <i className="fa ">&#xf095;</i> 0725 214 688
            </a>
            <a href="mailto:info@aropegroup.com">
              <i className="fa ">&#xf1fa;</i> victoria@aropegroup.com
            </a>
          </div>
        </div>
        <div className="hcontainer">
          <div className="wrapper">
            <NavLink
              txt="Go to Our Website"
              url="https://aropegroup.com"
              active={props.active}
            />
            <NavLink
              txt="Terms and Conditions"
              url="/terms"
              active={props.active}
            />
            <NavLink txt="Privacy Policy" url="/policy" active={props.active} />
            <a href={userguide} target="_blank">
              User Guide
            </a>
          </div>
          <div className="buttons">
            <button
              onClick={() => {
                props.setIsLogin(true);
                props.setIsRegister(false);
              }}
            >
              Login
            </button>
            <button
              onClick={() => {
                props.setIsLogin(false);
                props.setIsRegister(true);
              }}
            >
              Register
            </button>
          </div>
          <div className="mob">
            <i
              onClick={() => {
                setClicked(true);
              }}
              className="fa"
            >
              &#xf0c9;
            </i>
          </div>
        </div>
      </div>
      {clicked && (
        <HeaderModal
          logo={logo}
          setIsLogin={props.setIsLogin}
          setIsRegister={props.setIsRegister}
          setClicked={setClicked}
        />
      )}
    </>
  );
}
