import { useEffect, useRef, useState } from "react";
import WaveLoading from "../Utils/WaveLoading";
import { BsInfoSquare } from "react-icons/bs";
import { GrInProgress } from "react-icons/gr";
import { BsFillPersonCheckFill } from "react-icons/bs";
import SubjectsPopup from "./SubjectsPopup";
import Input from "../Utils/Input";

export default function ReqeustPopup(props) {
  const [isLoading, setIsLoading] = useState(null);
  const [error, setError] = useState("");
  const [data, setData] = useState(null);
  const [fo, setFO] = useState(null);
  const [fodata, setFOData] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [jdets, setJDets] = useState(null);
  const [clicked, setClicked] = useState(null);
  const [udets, setUDets] = useState(null);
  const [tt, setTT] = useState(null);
  const [ct, setCT] = useState(null);
  const [body, setBody] = useState({
    RequestID: props.item.DocumentID,
    UserID: null,
    Assigned: true,
  });

  useEffect(() => {
    setIsLoading(true);
    fetch(`/api/subjects/checks/byjobid/${props.item.DocumentID}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error();
      })
      .then((data) => {
        if (data.length > 0) {
          let total = 0;
          let count = 0;
          data.map((item) => {
            const it = JSON.parse(item.Checks);
            total = total + it.length;
            it.map((i) => {
              if (!i.Status) {
                count += 1;
              }
            });
          });

          setTT(total);
          setCT(count);
        }
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  }, [refresh]);

  useEffect(() => {
    setIsLoading(true);

    fetch(`/api/requests/${props.item.DocumentID}`)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else throw Error("");
      })
      .then((data) => {
        setIsLoading(false);
        setJDets(data);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  }, [props.refresh, refresh]);

  useEffect(() => {
    if (jdets) {
      setIsLoading(true);
      fetch(`/api/mobile/${jdets?.UserID}`)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else throw Error("");
        })
        .then((data) => {
          setIsLoading(false);
          setUDets(data);
   
        })
        .catch((e) => {
          setIsLoading(false);
        });
    }
  }, [jdets]);

  useEffect(() => {
    setData(null);
    setIsLoading(true);
    fetch(`/api/tasks/requestid/${props.item.DocumentID}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error();
      })
      .then((data) => {

        if (data.length > 0) {
          setData(data[0]);
        }
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  }, [refresh]);

  function searchByName(q) {
    fetch(`/api/auth/seachbyname/${q}`)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else throw Error();
      })
      .then((data) => {
        setFO(data);
      })
      .catch((e) => {});
  }

  const createDocument = (e) => {
    setError("");
    const chck = Object.values(body);
    let valid = true;
    chck.map((item) => {
      if (item === null) {
        valid = false;
      }
    });
    if (!valid) return setError("All fields are required!");
    setIsLoading(true);

    fetch("/api/tasks/create", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setIsLoading(false);
        if (data.success) {
          setError(data.success);
          setRefresh(!refresh);
        } else {
          setError(data.error);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setError("Oops! Something went wrong!");
      });
  };

  const List = (params) => {
    return (
      <div
        onClick={() => {
          let d = body;
          d.UserID = params.item.UserID;
          setBody(d);
          setFOData(params.item);
          setFO(null);
        }}
        className="s_list"
      >
        <p>
          {params.item.Name} - {params.item.Role}
        </p>
      </div>
    );
  };

    function getDocument() {
      setIsLoading(true);
      const txt = data.Attachment.slice(13);
      fetch(`/api/uploads/${data.Attachment}`)
        .then((res) => {
          if (res.ok) {
            return res.blob();
          } else throw Error("");
        })
        .then((blob) => {
          setIsLoading(false);
          const url = window.URL.createObjectURL(blob);
          window.open(url, "_blank");
        })
        .catch((e) => {
          setIsLoading(false);
          alert("File not found!");
        });
    }

  return (
    <div data-aos="fade-left" className="popup">
      <div className="pcontainer">
        <div className="tbar">
          <h3>{props.item.Title}</h3>

          <i
            onClick={() => {
              props.setShowing(null);
              props.setRefresh(!props.refresh);
            }}
            className="fa fa-times"
          ></i>
        </div>

        <div className="wcontent">
          <div className="div3equal">
            <div>
              <h5>
                <BsInfoSquare /> Basic Info
              </h5>
              <div className="details">
                <h3>Job Details</h3>
                <div className="u_list">
                  <p>
                    <b>Title: </b> {jdets && jdets?.Title}
                  </p>
                  <p>
                    <b>Description: </b> {jdets && jdets?.Description}
                  </p>
                  <p>
                    <b>Consent Form: </b>{" "}
                    {jdets &&
                      jdets?.Consent?.substring(13, jdets?.Consent?.length)}
                  </p>
                  <p>
                    <b>No of Subjects: </b>
                    {props.item.Clients ? props.item.Clients.length : 0}
                  </p>
                </div>

                <h3>My Details</h3>
                <div className="u_list">
                  <p>
                    <b>Name: </b> {udets && udets?.Name}
                  </p>
                  <p>
                    <b>Phone: </b> {udets && udets?.Phone}
                  </p>
                  <p>
                    <b>Email: </b> {udets && udets?.Email}
                  </p>
                  <p>
                    <b>Type: </b> {udets && udets?.Organisation}
                  </p>
                </div>
              </div>
            </div>
            <div>
              <h5>
                <GrInProgress /> Progress
              </h5>
              <div className="div prg">
                <Item txt="Received" status={true} />
                <Item
                  txt="Assigned to Staff"
                  status={data ? data.Received : null}
                />
                <Item
                  txt="Actual Checks"
                  status={data ? data.Checking : null}
                  tally={
                    tt && ct
                      ? ` ${tt - ct}/${tt} --- ${(
                          ((tt - ct) / tt) *
                          100
                        ).toFixed(0)}%`
                      : null
                  }
                />
                <Item
                  txt="Report Preparation"
                  status={data ? data.ReportPreparation : null}
                />
                <Item
                  txt="Report Submission"
                  status={data ? data.ReportSubmitted : null}
                />
              </div>
            </div>
            <div>
              <h5>
                <BsFillPersonCheckFill /> Subject Details
              </h5>
              <div className="u_list">
                <div>
                  {data && data.ReportID ? (
                    <>
                      <p>
                        Report generated on: {data.updatedAt.split("T")[0]}{" "}
                        {data.updatedAt.split("T")[1].substring(0, 5)}
                      </p>

                      <h5
                        onClick={() => {
                          getDocument();
                        }}
                      >
                        View Report
                      </h5>
                    </>
                  ) : (
                    <p>Report is pending</p>
                  )}
                  <br />
                  <div className="u_list">
                    <p>
                      <b>No of Subjects: </b>
                      {props.item.Clients ? props.item.Clients.length : 0}
                    </p>
                    <button
                      onClick={() => {
                        setClicked(true);
                      }}
                      style={{ backgroundColor: "orange" }}
                    >
                      View Subject(s)
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {isLoading && <WaveLoading />}
        </div>
      </div>
      {jdets && clicked && (
        <SubjectsPopup
          JobID={props.item.DocumentID}
          Title={props.item.Title}
          setShowing={setJDets}
          setClicked={setClicked}
          refresh={refresh}
          setRefresh={setRefresh}
          task={props.task}
        />
      )}
    </div>
  );
}

const Item = (props) => {

  const [color, setColor] = useState("red");

  useEffect(() => {
    switch (props.status) {
      case true:
        setColor("green");
        break;
      case false:
        setColor("orange");
        break;
      case null:
        setColor("red");
        break;
      default:
        setColor("red");
        break;
    }
  }, [props.status]);

  return (
    <div className="item">
      <div style={{ backgroundColor: color }}></div>
      <p>
        {props.txt} <b>{props.tally ? props.tally : ""}</b>
      </p>
    </div>
  );
};

const SelectedUser = (props) => {
  return (
    <>
      {props.data && (
        <div className="selected_staff">
          <div
            onClick={() => {
              props.clearEvent();
            }}
            className="fa fa-times"
          ></div>
          <p>{props.data.Name}</p>
          <p>{props.data.Phone}</p>
        </div>
      )}
    </>
  );
};
