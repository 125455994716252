import "../../Styles/requests.scss";
import AccordionBody from "../Utils/AccordionBody";
import { useEffect, useState } from "react";
import Pagination from "../Utils/Pagination";
import { VscNewFolder, VscTasklist } from "react-icons/vsc";
import { RiTaskLine } from "react-icons/ri";
import WaveLoading from "../Utils/WaveLoading";
import MyError from "../Utils/MyError";

export default function Requests(props) {
  const [requestsn, setRequestsn] = useState(null);
  const [requestsa, setRequestsa] = useState(null);
  const [requestsc, setRequestsc] = useState(null);
  const [show, setShow] = useState(false);
  const [offsetn, setOffsetn] = useState(0);
  const [offseta, setOffseta] = useState(0);
  const [offsetc, setOffsetc] = useState(0);
  const [loading, setLoading] = useState(true);
  const [showing, setShowing] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [UserID, setUserID] = useState(null);
  const [arrow, setArrow] = useState("fa fa-angle-up");
  const jwt = require("jsonwebtoken");
  const handleResize = () => {
    if (window.innerWidth < 768) {
      setShowing(false);
    } else {
      setShowing(true);
    }
  };

  useEffect(() => {
    handleResize();
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("webgfgfgggn");
    if (token) {
      try {
        var decoded = jwt.decode(token);
        if (Date.now() >= decoded.exp * 1000) {
          window.location.href = "/";
        } else {
          setUserID(decoded);
        }
      } catch (error) {
        window.location.href = "/";
      }
    } else {
      window.location.href = "/";
    }
  }, []);

  const toggle = () => {
    setShow(!show);
    if (show) {
      setArrow("fa fa-angle-down");
    } else setArrow("fa fa-angle-up");
  };

  useEffect(() => {
    if (UserID) {
      setLoading(true);
      fetch(`/api/requests/client/paginated/Received/${offsetn}/${UserID.UserID}`, {
        method: "get",
        credentials: "include",
      })
        .then((res) => {
          if (!res.ok) {
            throw Error("Could not fetch messages!!!");
          }
          return res.json();
        })
        .then((data) => {
          console.log(data)
          setLoading(false);
          setRequestsn(data);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }, [offsetn, refresh, UserID]);

  useEffect(() => {
    if (UserID) {
      fetch(`/api/requests/client/paginated/Assigned/${offseta}/${UserID.UserID}`, {
        method: "get",
        credentials: "include",
      })
        .then((res) => {
          if (!res.ok) {
            throw Error("Could not fetch messages!!!");
          }
          return res.json();
        })
        .then((data) => {
          setRequestsa(data);
        })
        .catch((err) => { });
    }
  }, [offseta, refresh, UserID]);

  useEffect(() => {
    if (UserID) {
      fetch(`/api/requests/client/paginated/Complete/${offsetc}/${UserID.UserID}`, {
        method: "get",
        credentials: "include",
      })
        .then((res) => {
          if (!res.ok) {
            throw Error("Could not fetch messages!!!");
          }
          return res.json();
        })
        .then((data) => {
          setRequestsc(data);
        })
        .catch((err) => { });
    }
  }, [offsetc, refresh, UserID]);

  return (
    <div className="requests">
      <div className="div3equal">
        <div className="litem">
          <h3>
            <VscNewFolder /> New
          </h3>
          <div className="content">
            {requestsn && requestsn?.data?.length > 0 ? (
              requestsn?.data?.map((item, index) => {
                return (
                  <AccordionBody
                    item={item}
                    key={index}
                    type="Received"
                    refresh={refresh}
                    setRefresh={setRefresh}
                    date={item?.createdAt?.split("T")[0]}
                    description={
                      item.Description.length > 30 ? item.Description : ""
                    }
                    file={item.File}
                  />
                );
              })
            ) : (
              <MyError txt="No Data" />
            )}
          </div>

          {requestsn && requestsn?.data?.length > 0 && (
            <>
              <br></br>
              <Pagination
                page={offsetn}
                setOffset={setOffsetn}
                total={requestsn.total}
              />
            </>
          )}
        </div>
        <div className="litem">
          <h3>
            <VscTasklist /> In Progress
          </h3>
          <div className="content">
            {requestsa && requestsa?.data?.length > 0 ? (
              requestsa?.data?.map((item, index) => {
                return (
                  <AccordionBody
                    item={item}
                    key={index}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    type="Assigned"
                    date={item.createdAt.split("T")[0]}
                    description={
                      item.Description.length > 30 ? item.Description : ""
                    }
                    file={item.File}
                  />
                );
              })
            ) : (
              <MyError txt="No Data" />
            )}
          </div>

          {requestsa && requestsa?.data?.length > 0 && (
            <>
              <br></br>
              <Pagination
                page={offseta}
                setOffset={setOffseta}
                total={requestsa.total}
              />
            </>
          )}
        </div>
        <div className="litem">
          <h3>
            <RiTaskLine /> Complete
          </h3>
          <div className="content">
            {requestsc && requestsc?.data?.length > 0 ? (
              requestsc?.data?.map((item, index) => {
                return (
                  <AccordionBody
                    item={item}
                    key={index}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    type="Completed"
                    date={item.createdAt.split("T")[0]}
                    description={
                      item.Description.length > 30 ? item.Description : ""
                    }
                    file={item.File}
                  />
                );
              })
            ) : (
              <MyError txt="No Data" />
            )}
          </div>

          {requestsc && requestsc?.data?.length > 0 && (
            <>
              <br></br>
              <Pagination
                page={offsetc}
                setOffset={setOffsetc}
                total={requestsc.total}
              />
            </>
          )}
        </div>
      </div>

      {loading && <WaveLoading />}
    </div>
  );
}
